import { ReactElement } from "react";

import { FrigadeProvider } from "@frigade/react";
import * as Frigade from "@frigade/reactv2";
import { useRouter } from "next/router";

import FRIGADE_CONFIG from "modules/frigade/constants";
import FrigadeProperties from "modules/frigade/FrigadeProperties";
import useAuth from "utils/hooks/useAuth";

function FrigadeProviderWrapper({ children }: { children: ReactElement }): ReactElement {
  const { userId } = useAuth();
  const router = useRouter();

  if (!process.env.NEXT_PUBLIC_FRIGADE_PUBLIC_API_KEY) {
    return children;
  }

  return (
    <Frigade.Provider apiKey={process.env.NEXT_PUBLIC_FRIGADE_PUBLIC_API_KEY} userId={userId}>
      <FrigadeProvider
        config={{
          defaultAppearance: FRIGADE_CONFIG.APPEARANCE,
          navigate: (url, target): void => {
            if (target === "_blank") {
              window.open(url, "_blank");
            } else {
              router.push(url);
            }
          },
        }}
        publicApiKey={process.env.NEXT_PUBLIC_FRIGADE_PUBLIC_API_KEY}
        userId={userId}
      >
        <FrigadeProperties>{children}</FrigadeProperties>
      </FrigadeProvider>
    </Frigade.Provider>
  );
}

export default FrigadeProviderWrapper;
