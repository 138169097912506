/* eslint-disable no-console */
import * as Sentry from "@sentry/nextjs";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import smoothscroll from "smoothscroll-polyfill";
import parse from "ua-parser-js";

import { CandidateFetchResponse, ExperienceResponse, ProjectResponse } from "interfaces/API";
import { monthAbbreviations } from "utils/constants";

export const isUuid = (value: string): boolean =>
  value.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i) !== null;

export function getCookie(name: string): string | null {
  // Split cookie string and get all individual name=value pairs in an array
  const cookieArr = document.cookie.split(";");

  // Loop through the array elements
  for (let i = 0; i < cookieArr.length; i += 1) {
    const cookiePair = cookieArr[i].split("=");

    /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
    if (name === cookiePair[0].trim()) {
      // Decode the cookie value and return
      return decodeURIComponent(cookiePair[1]);
    }
  }

  // Return null if not found
  return null;
}

export function getBrowserName(setBrowserName: (arg0: string) => void): void {
  const browserInfo = parse(navigator.userAgent);
  if (browserInfo.engine.name === "Blink") {
    /* @ts-ignore */
    if (navigator.brave) {
      /* @ts-ignore */
      navigator.brave.isBrave().then((result) => {
        if (result) setBrowserName("Brave");
      });
    } else if (browserInfo.browser.name) setBrowserName(browserInfo.browser.name);
  } else if (browserInfo.engine.name === "Gecko") {
    if (browserInfo.browser.name) setBrowserName(browserInfo.browser.name);
  } else if (browserInfo.engine.name === "WebKit") {
    if (browserInfo.browser.name === "Safari") {
      // TODO: future code goes here
    }
  }
}

export async function getBrowserExtensionString(): Promise<{ browserName: string }> {
  let browserName = "Chrome";
  const browserInfo = parse(navigator.userAgent);
  if (browserInfo.engine.name === "Blink") {
    /* @ts-ignore */
    if (navigator.brave) {
      /* @ts-ignore */
      const result = await navigator.brave.isBrave();
      if (result) browserName = "Brave";
    } else if (browserInfo.browser.name) browserName = browserInfo.browser.name;
  } else if (browserInfo.engine.name === "Gecko") {
    if (browserInfo.browser.name) browserName = browserInfo.browser.name;
  } else if (browserInfo.engine.name === "WebKit") {
    if (browserInfo.browser.name === "Safari") {
      // TODO: future code goes here
    }
  }
  return { browserName };
}

export function getLandingLink(setBrowserName: (arg0: string) => void, setIsMobile?: (arg0: boolean) => void): void {
  const browserInfo = parse(navigator.userAgent);
  if (browserInfo.device.type === "mobile" || browserInfo.device.type === "tablet") {
    setIsMobile?.(true);
  }
  if (browserInfo.engine.name === "Blink" || browserInfo.engine.name === "Gecko") {
    /* @ts-ignore */
    if (navigator.brave) {
      /* @ts-ignore */
      navigator.brave.isBrave().then((result) => {
        if (result) setBrowserName("Brave");
      });
    } else if (browserInfo.browser.name) setBrowserName(browserInfo.browser.name);
  }
}

export function scrollToSection(id: string, block = "start"): void {
  smoothscroll.polyfill();
  /* @ts-ignore */
  document.getElementById(id)?.scrollIntoView({ behavior: "smooth", block });
}

// TODO: Remove once api for profile complete exists
export function profileComplete(profile: CandidateFetchResponse): boolean {
  if (
    // profile.ethnicity === null ||
    // profile.ethnicity.length === 0 ||
    // profile.location === null ||
    // profile.resume_preview === null ||
    // profile.resume_preview === "" ||
    profile.resume_last_uploaded === null ||
    profile.resume_last_uploaded === "" ||
    profile.skill === null ||
    profile.skill.length === 0 ||
    (profile.work_auth_us === null && profile.work_auth_ca === null && profile.work_auth_uk === null) ||
    profile.sponsorship === null ||
    // profile.disability_v2 === null ||
    profile.education === null ||
    profile.education?.length === 0 ||
    ((profile.experience === null || profile.experience?.length === 0) &&
      (profile.project === null || profile.project?.length === 0))
    // profile.veteran_v2 === null ||
    // profile.lgbt_v2 === null ||
    // profile.gender === null
  ) {
    return false;
  }
  return true;
}

export function getExperienceEndYear(object: ExperienceResponse | ProjectResponse, optional = false): string {
  if (!optional)
    return object.currently_working
      ? "Present"
      : /* @ts-ignore */
        `${monthAbbreviations[object.end_month]} ${object.end_year.toString()}`;
  return object.currently_working
    ? "Present"
    : `${object.end_month ? monthAbbreviations[object.end_month] : ""} ${
        object.end_year ? object.end_year.toString() : ""
      }`;
}

export function urlFriendlyFormat(text: string): string {
  return text
    .replace(/ - /g, "-")
    .replace(/ /g, "-")
    .replace(/[^a-zA-Z0-9-_]/g, "");
}

export function reportInfiniteScrollIssues(
  page: number,
  pages: number,
  hasMore: boolean,
  hits: number,
  total: number
): void {
  if ((page >= pages && page !== 0) || hits > total || (page < pages - 1 && !hasMore))
    Sentry.captureMessage(
      `Infinite Scroll Issue: Page: ${page}, Total Pages: ${pages}, Displayed Hits: ${hits}, Total Hits: ${total}, hasMore: ${hasMore}`
    );
}

export function checkSearchMatch(searchRefValue: string | null, url: string | undefined): boolean {
  if ("URLSearchParams" in window && typeof searchRefValue === "string" && url) {
    const searchInURL = new URLSearchParams(url).get("value");
    if (searchInURL !== searchRefValue) return false;
  }
  return true;
}

export function addUTMSource(url: string): string {
  let source = "utm_source=Simplify";
  if (url.includes("lever")) {
    source = "lever-source=Simplify";
  }
  if (url.includes("breezy.hr")) {
    source = "source=Simplify";
  }
  if (url.includes("?")) return `${url}&${source}`;
  return `${url}?${source}`;
}

export function isLocalStorageItemTrue(key: string): boolean {
  const item = localStorage.getItem(key);
  if (item) return JSON.parse(item);
  return false;
}

export function sortMatchesByViewed(a: { viewed_last: string | null }, b: { viewed_last: string | null }): number {
  if (!a.viewed_last && !b.viewed_last) return 0;
  if (!a.viewed_last && b.viewed_last) return -1;
  if (a.viewed_last && !b.viewed_last) return 1;
  // @ts-ignore
  if (a.viewed_last < b.viewed_last) return -1;
  // @ts-ignore
  if (a.viewed_last > b.viewed_last) return 1;
  return 0;
}

export function utmSourceUrl(url: string): string {
  return `${url}?utm_source=Simplify`;
}

export function convertToNumber(num: string | number): number {
  if (typeof num === "string") return parseInt(num, 10);
  return num;
}

export function getFileExtension(fileName: string): string {
  const parts = fileName.split(".");
  const extension = parts[parts.length - 1];
  return extension.toLowerCase();
}

export function cleanMoney(data: number): string {
  return `$${Intl.NumberFormat("en-US", {
    notation: "compact",
    maximumFractionDigits: 1,
  }).format(data)}`;
}

export function customFormatPhoneNumber(phoneNumber: string): string {
  const formattedNumber = formatPhoneNumberIntl(phoneNumber);

  const parts = formattedNumber.split(" ");

  if (parts?.length === 4 && parts[0] === "+1") {
    return `${parts[0]} (${parts[1]}) ${parts[2]}-${parts[3]}`;
  }

  return formattedNumber;
}

export function stripNullCharacters<T>(obj: T): T {
  if (obj === null || obj === undefined) return obj;

  let str = JSON.stringify(obj);

  str = str.replace(/\0/g, "");

  str = str.replace(/\\\\u0000/g, ""); // Double-escaped Unicode (from previous JSON.stringify)
  str = str.replace(/\\u0000/g, ""); // JSON-escaped Unicode

  try {
    return JSON.parse(str);
  } catch (e) {
    const sanitized = str.split("\u0000").join("");
    return JSON.parse(sanitized);
  }
}
