import { Component, ReactNode } from "react";

import * as Sentry from "@sentry/nextjs";
import Link from "next/link";

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  errorMessage?: string;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    // eslint-disable-next-line react/state-in-constructor
    this.state = { hasError: false, errorMessage: "" };
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error): void {
    this.setState({ errorMessage: error.message });
    Sentry.captureException(error);
  }

  render(): ReactNode {
    const { hasError, errorMessage } = this.state;
    if (hasError) {
      return (
        <div className="min-h-screen bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
          <div className="mx-auto max-w-max">
            <main className="sm:flex">
              <p className="text-4xl font-black text-primary-400 sm:text-5xl">Oops!</p>
              <div className="sm:ml-6">
                <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                  <h1 className="text-4xl font-black tracking-tight text-gray-900 sm:text-5xl">Application error</h1>
                  <p className="mt-2 text-base text-gray-500">
                    A client-side exception has occurred (see the browser console for more information).
                  </p>
                  <p className="text-base text-gray-500">
                    Please click the contact support button to report this issue.
                  </p>
                </div>
                <div className="mt-8 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                  <Link
                    className="inline-flex items-center rounded-md border border-transparent bg-primary-400 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-primary-50 focus:ring-offset-2"
                    href="/jobs"
                  >
                    Browse Jobs
                  </Link>
                  <a
                    className="inline-flex items-center rounded-md border border-transparent bg-primary-100 px-4 py-2 text-sm font-medium text-primary-400 hover:bg-primary-200 focus:outline-none focus:ring-2 focus:ring-primary-50 focus:ring-offset-2"
                    href={`mailto:support@simplify.jobs?subject=Client Side Exception&body=${errorMessage} - URL: ${window.location.href}`}
                  >
                    Contact support
                  </a>
                </div>
              </div>
            </main>
          </div>
        </div>
      );
    }

    const { children } = this.props;
    return children;
  }
}

export default ErrorBoundary;
