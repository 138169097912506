/* eslint-disable camelcase */
import * as Sentry from "@sentry/nextjs";

import { FetchResponse } from "interfaces/API";
import { PREVENT_EXTENSION_DOWNLOAD_POPUP_KEY } from "utils/constants";
import { isLocalStorageItemTrue } from "utils/helpers/helpers";
import { sendSabreMessage } from "utils/helpers/sabre";

import { fetchWrapper } from "./fetch";

export const getJobPostingById = async (job_posting_id: string): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/job-posting/:id/${job_posting_id}`,
    auth: true,
  });

export const getJobPostingByIdWithCompany = async (job_posting_id: string): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/job-posting/:id/${job_posting_id}/company`,
  });

export const getJobPostingByTrackedObj = async (tracked_obj: string): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/job-posting/:tracked_obj/base/${encodeURIComponent(tracked_obj)}`,
    auth: true,
  });

export const getJobPostingCompanyByTrackedObj = async (tracked_obj: string): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/job-posting/:tracked_obj/company/${encodeURIComponent(tracked_obj)}`,
    auth: true,
  });

export const getJobPostingAnalytics = async (job_posting_id: string): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/job-posting/:id/${job_posting_id}/analytics`,
    auth: true,
  });

export const apply = async (
  setShowExtensionModal: (arg0: boolean) => void,
  setShowAppliedModal: (arg0: boolean) => void,
  url: string,
  setLoadingButton?: (arg0: boolean) => void
): Promise<boolean> => {
  setLoadingButton?.(true);
  const skipExtensionModal = isLocalStorageItemTrue(PREVENT_EXTENSION_DOWNLOAD_POPUP_KEY);

  try {
    const installed = await sendSabreMessage({ method: "version" }, 150, 10);
    if (installed) {
      const response = await sendSabreMessage({ method: "applyToJob", url }, 5000, 0);
      if (response === true) {
        setShowExtensionModal(false);
        setLoadingButton?.(false);
        return true;
      }
    }

    if (skipExtensionModal) {
      window.open(url, "_blank");
      setShowAppliedModal(true);
      setLoadingButton?.(false);
    } else
      setTimeout(() => {
        setShowExtensionModal(true);
        setLoadingButton?.(false);
      }, 500);

    return false;
  } catch (error: any) {
    if (
      error.message !== "Cannot read properties of undefined (reading 'sendMessage')" &&
      error.message !== "Sabre Message Timed out"
    )
      Sentry.captureException(error);
    if (skipExtensionModal) {
      window.open(url, "_blank");
      setShowAppliedModal(true);
      setLoadingButton?.(false);
    } else
      setTimeout(() => {
        setShowExtensionModal(true);
        setLoadingButton?.(false);
      }, 500);
    return false;
  }
};
