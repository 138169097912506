import ShortUniqueId from "short-unique-id";
import parse from "ua-parser-js";

/**
 * Methods Dunder can use to communicate with Sabre.
 */
export type DunderMethod =
  | "applyToJob"
  | "getSessionItem"
  | "importProfile"
  | "installed"
  | "removeSessionItem"
  | "setResumeId"
  | "setSessionItem";

/**
 * The event Dunder dispatches to Sabre when it needs something.
 */
export type DunderMessageEvent = Record<string, unknown> & {
  sender: "dunder";
  method: DunderMethod;
  suid: string;
};

/**
 * The event dispatched by Sabre when responding to Dunder.
 */
export interface UpdateDunderMessageEvent {
  sender: "sabre";
  suid: string;
  message?: unknown;
  error?: string;
}

export const sendSabreMessage = <T = unknown>(
  message: Omit<DunderMessageEvent, "sender" | "suid">,
  time = 500,
  retries = 20
): Promise<T> =>
  new Promise<T>((resolve, reject) => {
    let currentRetries = retries;
    const browserInfo = parse(navigator.userAgent);

    if (browserInfo.browser.name === "Safari") {
      reject(new Error("Safari not supported"));
      return;
    }

    let resolved = false;
    let timeout: ReturnType<typeof setTimeout>;
    const suid = new ShortUniqueId().randomUUID();

    const listener = (event: MessageEvent<UpdateDunderMessageEvent>): void => {
      if (resolved || event.source !== window || event.data?.sender !== "sabre" || event.data.suid !== suid) {
        return;
      }

      const response = event.data;

      console.debug("[Simplify]: Received Sabre response:", { message, response });

      if (timeout) {
        clearTimeout(timeout);
      }

      resolved = true;
      window.removeEventListener("message", listener);

      if (response?.error) {
        reject(new Error(response.error || "Unknown error"));
      } else {
        resolve(response.message as T);
      }
    };

    const send = (): void => {
      if (resolved) {
        return;
      }

      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = setTimeout(() => {
        if (resolved) {
          return;
        }

        if (currentRetries > 0) {
          currentRetries -= 1;
          send();
        } else {
          console.warn("[Simplify]: Sabre message timed out");
          resolved = true;
          reject(new Error("Sabre Message Timed out"));
        }
      }, time);

      console.debug("[Simplify]: Sending Sabre message:", message);
      window.postMessage({ sender: "dunder", suid, ...message });
    };

    window.addEventListener("message", listener);
    send();
  });

export const isExtensionInstalled = async (setInstalled: (arg0: boolean) => void): Promise<boolean> => {
  try {
    const browserInfo = parse(navigator.userAgent);

    if (browserInfo.device.type === "mobile") {
      setInstalled(false);
      return false;
    }

    const response = await sendSabreMessage({ method: "installed" });

    if (response === true) {
      setInstalled(true);
      return true;
    }

    setInstalled(false);
    return false;
  } catch (error) {
    setInstalled(false);
    return false;
  }
};
