/* eslint-disable no-param-reassign */
import * as Sentry from "@sentry/nextjs";

import { FetchResponse } from "interfaces/API";

import { COOKIE_NAME, ERROR_UNKNOWN_MSG } from "../constants";
import { getCookie } from "../helpers/helpers";

const endpoint = process.env.NEXT_PUBLIC_API_ENDPOINT;

const networkError = "Network request failed: Turn off any VPN & check internet connection";

const handleResult = (
  successStatus: boolean,
  statusCode: number,
  url: string,
  result: { detail: any }
): FetchResponse => {
  const resultObject = {
    success: successStatus,
    status: statusCode,
    url,
    data: {
      detail: "",
    },
    error: { detail: "" },
  };
  if (!successStatus) {
    const errorDetail = result.detail;
    if (typeof errorDetail === "string") {
      resultObject.error = result;
    } else if (Array.isArray(errorDetail)) {
      // show first error message in the stack
      resultObject.error = { detail: result.detail[0].msg };
    } else {
      resultObject.error = { detail: ERROR_UNKNOWN_MSG };
    }
  } else {
    resultObject.data = result;
  }
  if (resultObject.error && resultObject.error.detail.includes("User will have access here at")) {
    window.location.pathname = "/dashboard";
  }
  return resultObject;
};

const handleGeocodeResult = (
  successStatus: boolean,
  statusCode: number,
  url: string,
  result: { data: any; message?: string }
): FetchResponse => {
  const resultObject = {
    success: successStatus,
    status: statusCode,
    url,
    data: {},
    error: { detail: "" },
  };
  if (!successStatus) {
    if (result.message) {
      resultObject.error = { detail: result.message };
    } else if (result?.data?.geocoding?.errors?.length > 0) {
      resultObject.error = { detail: result.data.geocoding.errors[0] };
    } else {
      resultObject.error = { detail: ERROR_UNKNOWN_MSG };
    }
  } else {
    resultObject.data = result.data;
  }

  return resultObject;
};

// eslint-disable-next-line import/prefer-default-export
export const fetchWrapper = async (args: any): Promise<FetchResponse> => {
  if (!args.noHeader) {
    args.headers = {
      "Content-Type": "application/json",
      Client: "Dunder",
      ...args.headers, // So that content-type can be overridden when needed
    };
  } else {
    // this if else is required because we want the file upload headers not to be set for the browser
    // to handle content-type and the file boundaries automatically, which causes trouble when it is set manually
    args.headers = {};
  }

  if (args.auth) {
    args.headers["X-CSRF-TOKEN"] = `${getCookie(COOKIE_NAME)}`;
  }
  args.credentials = "include";
  const url = `${!args.internal ? endpoint : ""}${args.url}`;
  return fetch(url, args)
    .then(async (response) => {
      try {
        const result = args.blob ? await response.blob() : await response.json();
        return !args.internal
          ? handleResult(response.ok, response.status, response.url, result)
          : handleGeocodeResult(response.ok, response.status, response.url, result);
      } catch (error) {
        const err = new Error(
          `Error in fetch: ${error} for ${args.url}. Status: ${response.status} ${response.statusText}`
        );
        Sentry.captureException(err);
        throw err;
      }
    })
    .catch((error) => {
      const err = new Error(error);
      return {
        success: false,
        status: 0,
        data: null,
        url,
        error: {
          detail: err?.message === "TypeError: Failed to fetch" ? networkError : err?.message || ERROR_UNKNOWN_MSG,
          extra: error,
        },
      };
    });
};
