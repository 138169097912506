/* eslint-disable camelcase */
import { FetchResponse } from "interfaces/API";

import { fetchWrapper } from "./fetch";

// Education
export const getEducationOptions = async (page: number, size: number, value: string): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/education/?page=${page}&size=${size}&value=${value}`,
    auth: true,
  });

export const getEducationFromId = async (education_id: string): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/education/${education_id}`,
    auth: true,
  });

// Major
export const getMajorOptions = async (page: number, size: number, value: string): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/education/major?page=${page}&size=${size}&value=${value}`,
    auth: true,
  });

export const getMajorFromId = async (major_id: string): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/education/major/${major_id}`,
    auth: true,
  });

// Language
export const getLanguageOptions = async (page: number, size: number, value: string): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/language/?page=${page}&size=${size}&value=${value}`,
    auth: true,
  });

// Skill
export const getSkillOptions = async (
  page: number,
  size: number,
  value: string,
  featured?: boolean
): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/skill/?page=${page}&size=${size}&value=${value}${featured == null ? "" : `&featured=${featured}`}`,
    auth: true,
  });

export const getSkillFromId = async (skill_id: string): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/skill/${skill_id}`,
    auth: true,
  });

export const createSkill = async (name: string): Promise<FetchResponse> =>
  fetchWrapper({
    method: "POST",
    url: `/v2/skill/`,
    body: JSON.stringify({ name }),
    auth: true,
  });

// Industry
export const getIndustryOptions = async (page: number, size: number, value: string): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/industry/?page=${page}&size=${size}&value=${value}`,
    auth: true,
  });

export const getIndustryFromId = async (industry_id: string): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/industry/${industry_id}`,
    auth: true,
  });

// Regions
export const getRegionOptions = async (page: number, size: number, value: string): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/region/?page=${page}&size=${size}&value=${value}`,
    auth: true,
  });

export const getRegionFromId = async (region_id: string): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/region/${region_id}`,
    auth: true,
  });

// Functions
export const getFunctionOptions = async (page: number, size: number, value: string): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/function/?page=${page}&size=${size}&value=${value}`,
  });

export const getFunctionFromId = async (region_id: string): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/function/${region_id}`,
  });

export const getSubfunctionOptions = async (page: number, size: number, value: string): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/sub-function/?page=${page}&size=${size}&value=${value}`,
  });

export const getSubfunctionFromId = async (region_id: string): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/sub-function/${region_id}`,
  });

export const getFunctionCategories = async (page: number, size: number, value: string): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/function-category/?page=${page}&size=${size}&value=${value}`,
  });

// Location
export const getLocations = async (search: string): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: `/api/location?text=${search}`,
    auth: true,
    internal: true,
  });
