/* eslint-disable camelcase */
import { FetchResponse } from "interfaces/API";

import { fetchWrapper } from "./fetch";

export const userInfo = async (): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/user/me`,
    auth: true,
  });

export const updateUserData = async (user_data: any): Promise<FetchResponse> =>
  fetchWrapper({
    method: "PUT",
    url: "/v2/user/me",
    body: JSON.stringify(user_data),
    auth: true,
  });

export const getAffiliateId = async (): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/user/affiliate-id`,
    auth: true,
  });
