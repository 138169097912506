import { useEffect, useState } from "react";

import * as analytics from "utils/analytics";
import { useLimitedCandidateData } from "utils/hooks/swr/user/useLimitedCandidateData";
import { useSubscriptionStatus } from "utils/hooks/swr/user/useSubscriptionStatus";
import useAuth from "utils/hooks/useAuth";

export function AnalyticsWrapper({ children }: { children: React.ReactElement }): JSX.Element {
  const { userId, email, isAuthenticated } = useAuth();
  const {
    candidateId,
    onBoardingCompleted,
    preferencesCompleted,
    isLoading: isLoadingCandidateData,
  } = useLimitedCandidateData();
  const { isSubscribed, isLoading } = useSubscriptionStatus();
  const [analyticsInitiated, setInitiated] = useState(false);

  useEffect(() => {
    if (!analyticsInitiated && isAuthenticated && !isLoading && !isLoadingCandidateData) {
      analytics.setUserWithProperty(
        userId,
        {
          email,
          subscribed: isSubscribed,
          candidate_id: candidateId,
          loggedIn: true,
          onboardingCompleted: onBoardingCompleted,
          preferencesCompleted,
        },
        {}
      );
      if (isSubscribed) analytics.startSessionRecording();
      setInitiated(true);
    }
  }, [
    isSubscribed,
    userId,
    email,
    candidateId,
    isAuthenticated,
    analyticsInitiated,
    isLoading,
    isLoadingCandidateData,
    onBoardingCompleted,
    preferencesCompleted,
  ]);

  return children;
}
