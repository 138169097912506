import { POSTHOG_IDENTIFY_ID } from "./constants";

/* eslint-disable camelcase */
declare global {
  interface Window {
    posthog: any;
    zaraz: any;
  }
}

export const eventWithData = (name: string, data: any): void => {
  if (typeof window !== "undefined" && window.posthog) {
    window.posthog.capture(name, data);
  }
};

export const pageView = (): void => {
  if (typeof window !== "undefined" && window.posthog) {
    window.posthog.capture("$pageview");
  }
};

export const setUser = (id: string): void => {
  if (typeof window !== "undefined" && window.posthog) {
    window.posthog.identify(id);
  }
};

export const setUserWithProperty = (id: string, set: any, setOnce: any): void => {
  if (typeof window !== "undefined" && window.posthog) {
    window.posthog.identify(
      id, // required
      set, // optional
      setOnce // optional
    );
  }
};

export const setUserProperty = (properties: any): void => {
  if (typeof window !== "undefined" && window.posthog) {
    window.posthog.people.set(properties);
  }
};

export const setUserPropertyOnce = (properties: any): void => {
  if (typeof window !== "undefined" && window.posthog) {
    window.posthog.people.set_once(properties);
  }
};

export const resetUser = (): void => {
  if (typeof window !== "undefined" && window.posthog) {
    window.posthog.reset();
  }
};

export const isFeatureEnabled = (flag: string): boolean => {
  if (typeof window !== "undefined" && window.posthog) {
    return !!window.posthog.isFeatureEnabled(flag);
  }
  return false;
};

export const startSessionRecording = (): void => {
  if (typeof window !== "undefined" && window.posthog) {
    window.posthog.startSessionRecording?.();
  }
};

export const zarazTrack = (name: string, eventProperties?: any): void => {
  if (typeof window !== "undefined" && window.zaraz) {
    if (eventProperties) window.zaraz.track(name, eventProperties);
    else window.zaraz.track(name);
  }
};

export const removeExistingIdentify = (id?: string): void => {
  // If identified on previous register, reset user
  const identified = localStorage?.getItem(POSTHOG_IDENTIFY_ID);
  if (identified) {
    localStorage.removeItem(POSTHOG_IDENTIFY_ID);
    if (id && id === identified) return;
    resetUser();
  }
};
