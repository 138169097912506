/* eslint-disable camelcase */
import { FetchResponse } from "interfaces/API";

import { fetchWrapper } from "./fetch";

export const getJobById = async (job_id: string): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/job/${job_id}`,
    auth: true,
  });

export const getJobInsights = async (job_id: string): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/job/${job_id}/insight`,
    auth: true,
  });

export const getJobReviews = async (job_id: string): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/job/${job_id}/review`,
    auth: true,
  });

export const getJobTags = async (job_id: string): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/job/${job_id}/tag`,
    auth: true,
  });
