import { ThemeConfig } from "react-select";

import { MetricOption } from "interfaces/API";

export const COOKIE_NAME = "csrf";
export const ACCESS_TOKEN_NAME = "access_token";
export const ERROR_UNKNOWN_MSG = "Unknown error. Please try again or refresh the page.";
export const GOOGLE_OAUTH_ROUTE = "/v2/auth/oauth/google/login";
export const LINKEDIN_OAUTH_ROUTE = "/v2/auth/oauth/linkedin/login";
export const API_RETRY_COUNT = 3;
export const DEACTIVATED_USER_MESSAGE = "This user is currently deactivated.";
export const ALREADY_APPLIED_MESSAGE = "The job posting has already been applied to the candidate's tracker";
export const MAX_VALUES_PER_FACET = 50;
export const INSTALL_PAGE = "https://simplify.jobs/install";

// Local Storage Keys
export const PREVENT_EXTENSION_DOWNLOAD_POPUP_KEY = "Simplify:Prevent-Extension-Popup";
export const PREVENT_PROFILE_INCOMPLETE_POPUP_KEY = "Simplify:Prevent-Profile-Incomplete-Popup";
export const APPLY_AFTER_LOGIN = "Simplify:Apply-After-Login-Id";
export const POSTHOG_IDENTIFY_ID = "simplify-ph-identify-id";

export const ethnicities = [
  { label: "Black/African American", value: "African American" },
  { label: "East Asian", value: "East Asian" },
  { label: "Hispanic/Latinx", value: "Hispanic/Latinx" },
  { label: "Middle Eastern", value: "Middle Eastern" },
  { label: "Southeast Asian", value: "Southeast Asian" },
  { label: "South Asian", value: "South Asian" },
  { label: "Native Hawaiian/Pacific Islander", value: "Native Hawaiian/Pacific Islander" },
  { label: "Native American/Alaskan", value: "Native American/Alaskan" },
  { label: "White", value: "White" },
];

export const honors = [
  { label: "Dean's List", value: "Dean's List" },
  { label: "USAMO Qualifier", value: "USAMO Qualifier" },
  { label: "MOP Qualifier", value: "MOP Qualifier" },
  { label: "ISEF Qualifier", value: "ISEF Qualifier" },
];

export const areas = [
  { label: "Northeast", value: "Northeast" },
  { label: "East Coast", value: "East Coast" },
  { label: "Midwest", value: "Midwest" },
  { label: "South", value: "South" },
  { label: "Southwest", value: "Southwest" },
  { label: "West Coast", value: "West Coast" },
  { label: "Pacific Northwest", value: "Pacific Northwest" },
];

export const roleTypes = {
  1: "Internship",
  2: "Full-Time",
  3: "Both",
};

export const roleTypesOptions = [
  { label: "Internship", value: 1 },
  { label: "Full-Time", value: 2 },
  { label: "Both", value: 3 },
];

export const roleTypesSelect = [
  { label: "Internship", value: 1 },
  { label: "Full-Time", value: 2 },
  { label: "Part-Time", value: 3 },
];

export const experienceLevelKeys = [
  "prefers_intern",
  "prefers_entry",
  "prefers_junior",
  "prefers_mid",
  "prefers_senior",
  "prefers_expert",
];

export const experienceLevels = {
  prefers_intern: "Internship",
  prefers_entry: "Entry Level/New Grad",
  prefers_junior: "Junior",
  prefers_mid: "Mid Level",
  prefers_senior: "Senior",
  prefers_expert: "Expert or higher",
};

export const experienceLevelsOG = ["Entry", "Junior", "Mid", "Senior", "Expert"];

export const months = [
  { label: "January", value: 1 },
  { label: "February", value: 2 },
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "May", value: 5 },
  { label: "June", value: 6 },
  { label: "July", value: 7 },
  { label: "August", value: 8 },
  { label: "September", value: 9 },
  { label: "October", value: 10 },
  { label: "November", value: 11 },
  { label: "December", value: 12 },
];

export const monthAbbreviations = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};

const currentYear = new Date().getFullYear();
export const years = Array(currentYear - 1960)
  .fill(undefined)
  .map((val, i) => ({ label: currentYear - i, value: currentYear - i }));

const collegeEndYear = currentYear + 10;
export const collegeYears = Array(collegeEndYear - 1930)
  .fill(undefined)
  .map((val, i) => ({ label: collegeEndYear - i, value: collegeEndYear - i }));

export const degrees = [
  { label: "Bachelor's", value: 1 },
  { label: "Master's", value: 2 },
  { label: "MBA", value: 3 },
  { label: "PhD", value: 5 },
  { label: "PharMD", value: 4 },
  { label: "Associate's", value: 6 },
  { label: "JD", value: 7 },
  { label: "MD", value: 8 },
  { label: "Bootcamp", value: 9 },
  { label: "Certification", value: 10 },
  { label: "Incomplete", value: 11 },
];

export const degreesArray = {
  1: "Bachelor's",
  2: "Master's",
  3: "MBA",
  5: "PhD",
  4: "PharMD",
  6: "Associate's",
  7: "JD",
  8: "MD",
  9: "Bootcamp",
  10: "Certification",
  11: "Incomplete",
};

export const onboardingValues = {
  additional_url: "",
  birthday: "",
  disability_v2: "",
  education: [
    {
      education_id: "",
      major: "",
      degree: "",
      gpa: null,
      start_month: null,
      start_year: null,
      grad_month: null,
      grad_year: null,
    },
  ],
  ethnicity: [],
  experience: [
    {
      company: "",
      location: "",
      title: "",
      type: "",
      start_month: "",
      start_year: "",
      end_month: "",
      end_year: "",
      description: "",
      currently_working: false,
    },
  ],
  gender: "",
  github: "",
  honors: [],
  interests: [],
  lgbt_v2: "",
  linkedin: "",
  location: "",
  phone: "",
  portfolio: "",
  resume: "",
  resume_last_uploaded: "",
  skills: [],
  sponsorship: "",
  veteran_v2: "",
  work_auth_us: "",
  work_auth_ca: "",
  work_auth_uk: "",
};

export const tagIcons = {
  0: {
    name: "Finance",
    icon: "/images/graphics/tags/money.png",
    text: "text-red-800",
    background: "bg-red-100",
  },
};

export const primaryTheme: ThemeConfig = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#F0FCFF",
    primary50: "#ccf6ff",
    primary75: "#ccf6ff",
    primary: "#3ec0de",
  },
});

export const companySizeEnum = {
  1: "1-10",
  2: "11-50",
  3: "51-200",
  4: "201-500",
  5: "501-1,000",
  6: "1,001-5,000",
  7: "5,001-10,000",
  8: "10,001+",
};

export const additionalRequirements: { [key: number]: string } = {
  1: "US Authorization",
  2: "US Citizenship",
  3: "US Top Secret Clearance",
  4: "Canada Authorization",
  5: "Canada Citizenship",
  6: "Canada Top Secret Clearance",
  7: "UK Authorization",
  8: "UK Citizenship",
  9: "UK Top Secret Clearance",
};

export const Reactions: {
  VIEWED_RECOMMENDATION: 1;
  CLICKED_RECOMMENDATION: 2;
  SAVED_JOB_FROM_RECOMMENDATION: 3;
  SENT_BY_EMAIL: 4;
  VIEWED_JOB_PAGE: 5;
  CLICKED_FROM_JOB_SEARCH: 6;
  SAVED_JOB_FROM_JOB_SEARCH: 7;
} = {
  VIEWED_RECOMMENDATION: 1,
  CLICKED_RECOMMENDATION: 2,
  SAVED_JOB_FROM_RECOMMENDATION: 3,
  SENT_BY_EMAIL: 4,
  VIEWED_JOB_PAGE: 5,
  CLICKED_FROM_JOB_SEARCH: 6,
  SAVED_JOB_FROM_JOB_SEARCH: 7,
};

export const navigation = [
  { name: "Copilot", href: "/copilot" },
  { name: "Job Application Tracker", href: "/job-application-tracker" },
  { name: "Resume Builder", href: "/resume-builder" },
  { name: "Employers", href: "/employers" },
  { name: "Blog", href: "/blog", hideOnMobile: true },
  // { name: "🚀 Moonshots", href: "/moonshot/web3" },
];
export const companyFundingMap = {
  LATER_STAGE_VC: "Later Stage VC",
  SEED: "Seed",
  M_AND_A: "M&A",
  UNKNOWN: "N/A",
  SERIES_A: "Series A",
  SERIES_B: "Series B",
  SERIES_C: "Series C",
  SERIES_D: "Series D",
  SERIES_E: "Series E",
  SERIES_F: "Series F",
  SERIES_G: "Series G",
  SERIES_H: "Series H",
  SERIES_I: "Series I",
  SERIES_J: "Series J",
  SERIES_K: "Series K",
  SERIES_L: "Series L",
  SERIES_M: "Series M",
  LATER_STAGE_SERIES_A: "Series A",
  LATER_STAGE_SERIES_B: "Series B",
  LATER_STAGE_SERIES_C: "Series C",
  LATER_STAGE_SERIES_D: "Series D",
  LATER_STAGE_SERIES_E: "Series E",
  LATER_STAGE_SERIES_F: "Series F",
  LATER_STAGE_SERIES_G: "Series G",
  LATER_STAGE_SERIES_H: "Series H",
  EARLY_STAGE_SERIES_A: "Series A",
  EARLY_STAGE_SERIES_B: "Series B",
  EARLY_STAGE_SERIES_C: "Series C",
  EARLY_STAGE_SERIES_D: "Series D",
  EARLY_STAGE_SERIES_E: "Series E",
  EARLY_STAGE_SERIES_F: "Series F",
  EARLY_STAGE_SERIES_G: "Series G",
  EARLY_STAGE_SERIES_H: "Series H",
  POST_IPO_EQUITY: "Post IPO Equity",
  SECONDARY_MARKET: "Private",
  POST_IPO_DEBT: "IPO",
  PRIVATE_EQUITY: "Private",
  SERIES_UNKNOWN: "N/A",
  GRANT: "Grant",
  IPO: "IPO",
  SECONDARY_PRIVATE: "Private",
  DEBT_PP: "N/A",
  BUYOUT_LBO: "Acquired",
  DEBT_FINANCING: "Debt Financing",
  EARLY_STAGE_VC: "Seed",
  EQUITY_CROWDFUNDING: "N/A",
  SEED_ROUND: "Seed",
  NON_EQUITY_ASSISTANCE: "N/A",
  CORPORATE_ROUND: "N/A",
  DEBT_GENERAL: "Debt Financing",
  FUNDING_ROUND: "N/A",
  CONVERTIBLE_NOTE: "N/A",
  ACCELERATOR_INCUBATOR: "Accelerator",
  OUT_OF_BUSINESS: "N/A",
  DEBT: "Debt Financing",
  DIV_RECAP: "N/A",
  SECONDARY_TRANSACTION_PRIVATE: "Private",
  POST_IPO_SECONDARY: "IPO",
  PRE__SEED: "Pre-seed",
  PRE_SEED: "Pre-seed",
  GP_STAKES: "N/A",
  PIPE: "N/A",
  MEZZANINE: "N/A",
  BANK_ADMIN: "N/A",
  ANGEL: "N/A",
  ACCELERATOR_INC: "Accelerator",
  UNDISCLOSED: "N/A",
  REVERSE_MERGER: "M&A",
  SERIES_A1: "Series A",
  SERIES_A2: "Series A",
  SERIES_A3: "Series A",
  SERIES_B1: "Series B",
  SERIES_B2: "Series B",
  SERIES_B3: "Series B",
  SERIES_C1: "Series C",
  SERIES_C2: "Series C",
  SERIES_C3: "Series C",
  SERIES_D1: "Series D",
  SERIES_D2: "Series D",
  SERIES_D3: "Series D",
  SERIES_1: "Series A",
  SERIES_2: "Series B",
  SERIES_3: "Series C",
  ANGEL_INDIVIDUAL: "N/A",
  LIQUIDATION: "N/A",
  CORPORATE: "N/A",
  INITIAL_COIN_OFFERING: "ICO",
  CROWDFUNDING: "N/A",
  DEBT_REFIN: "Debt Financing",
  EARLY_STAGE_SERIES_A1: "Series A",
  EARLY_STAGE_SERIES_B1: "Series B",
  EARLY_STAGE_SERIES_C1: "Series C",
  JOINT_VENTURE: "N/A",
  VENTURE_DEBT: "N/A",

  // New values from Dealroom
  // Not all of these are new, but the ones listed weren't covered by Harmonic
  ACQUISITION: "Acquired",
  BANKRUPTCY: "Bankrupt",
  BUYOUT: "Acquired",
  CONVERTIBLE: "N/A",
  CORPORATE_SPINOUT: "N/A",
  EARLY_VC: "Early VC",
  GROWTH_EQUITY_NON_VC: "Growth Equity (Non-Venture Capital)",
  GROWTH_EQUITY_VC: "Growth Equity (Venture Capital)",
  ICO: "ICO",
  LATE_VC: "Late Stage VC",
  LENDING_CAPITAL: "N/A",
  MEDIA_FOR_EQUITY: "N/A",
  MERGER: "M&A",
  POST_IPO_CONVERTIBLE: "IPO",
  PRIVATE_PLACEMENT_NON_VC: "N/A",
  PRIVATE_PLACEMENT_VC: "N/A",
  PROJECT_REAL_ESTATE_INFRASTRUCTURE_FINANCE: "N/A",
  SECONDARY: "Private",
  SPAC_IPO: "IPO",
  SPAC_PRIVATE_PLACEMENT: "N/A",
  SPINOUT: "N/A",
  SUPPORT_PROGRAM: "N/A",
};

export const metricOptions: Array<MetricOption> = [{ value: 9, label: "Headcount" }];

export const fundingStageOptions: Array<{ value: string; label: string }> = [
  { value: "SEED", label: "Seed" },
  { value: "SERIES_A", label: "Series A" },
  { value: "SERIES_B", label: "Series B" },
  { value: "SERIES_C", label: "Series C" },
  { value: "SERIES_D", label: "Series D" },
  { value: "SERIES_E", label: "Series E" },
  { value: "SERIES_F", label: "Series F" },
  { value: "IPO", label: "IPO" },
  { value: "POST_IPO_EQUITY", label: "Post IPO Equity" },
  { value: "LATE_VC", label: "Late VC" },
  { value: "EARLY_VC", label: "Early VC" },
];
