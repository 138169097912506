import { createContext, useContext, useEffect, useMemo, useState } from "react";

import { captureException } from "@sentry/nextjs";
import Script from "next/script";

import { useSubscriptionStatus } from "utils/hooks/swr/user/useSubscriptionStatus";
import useAuth from "utils/hooks/useAuth";

interface FeaturebaseContextType {
  loading: boolean;
}

const FeaturebaseContext = createContext<FeaturebaseContextType>({ loading: true });

export const useFeaturebase = (): FeaturebaseContextType => useContext(FeaturebaseContext);

export function FeaturebaseProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const { isAuthenticated, userId, email, firstName, loading: loadingAuth } = useAuth();
  const { isSubscribed, isLoading: loadingSubscribed } = useSubscriptionStatus();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loadingAuth || loading || loadingSubscribed || !isAuthenticated) return;

    const win = window as any;
    if (typeof win.Featurebase !== "function") {
      win.Featurebase = (): void => {
        // eslint-disable-next-line prefer-rest-params
        (win.Featurebase.q = win.Featurebase.q || []).push(arguments);
      };
    }

    win.Featurebase(
      "identify",
      {
        organization: "simplifyjobs",

        // Required fields. Replace with your customers data.
        email,
        name: firstName,
        userId,
        // Both email and userId should be provided when possible
        // At minimum, either email or userId must be present

        // !!! IMPORTANT !!!
        // Learn why you should set up identity verification and how to set it up:
        // https://help.featurebase.app/articles/7693175-what-is-identity-verification
        // This is optional but highly recommended.
        // userHash: "user-specific-hash-to-prevent-impersonation-attacks",

        // Optional - include other fields as needed
        customFields: {
          subscribed: isSubscribed.toString(),
        },
      },
      (err: Error) => {
        // Callback function. Called when identify completed.
        if (err) {
          captureException(err);
        }
      }
    );

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingAuth, loading, loadingSubscribed, isAuthenticated]);

  const contextValue = useMemo(() => ({ loading }), [loading]);

  return (
    <FeaturebaseContext.Provider value={contextValue}>
      <Script id="featurebase-sdk" src="https://do.featurebase.app/js/sdk.js" onLoad={() => setLoading(false)} />
      {children}
    </FeaturebaseContext.Provider>
  );
}
