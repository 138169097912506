import { createContext } from "react";

import { AuthResponse } from "interfaces/API";

const AuthContext = createContext<AuthResponse>({
  userId: "",
  userType: 1,
  isAuthenticated: false,
  loading: true,
  email: "",
  firstName: "",
  lastName: "",
  profileImage: "",
  createdAt: "",
  logout: () => undefined,
  login: async () => ({
    success: false,
    status: 404,
    data: "",
    error: {
      detail: "",
      extra: "",
    },
  }),
  refreshAuth: () => undefined,
  updateName: () => undefined,
});

export default AuthContext;
