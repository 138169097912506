import { ReactElement, useEffect, useState } from "react";

import { useUser } from "@frigade/react";

import { useSubscriptionStatus } from "utils/hooks/swr/user/useSubscriptionStatus";
import useAuth from "utils/hooks/useAuth";

function FrigadeProperties({ children }: { children: ReactElement }): ReactElement {
  const { firstName, lastName, userType, createdAt, email, loading } = useAuth();
  const { isSubscribed, isLoading } = useSubscriptionStatus();
  const [hasAddedProperties, setHasAddedProperties] = useState(false);
  const { addPropertiesToUser } = useUser();

  useEffect(() => {
    if (!loading && !isLoading && firstName && lastName && userType && createdAt && email && !hasAddedProperties) {
      setHasAddedProperties(true);
      addPropertiesToUser({
        firstName,
        lastName,
        createdAt,
        email,
        userType,
        isSubscribed,
      });
    }
  }, [
    addPropertiesToUser,
    setHasAddedProperties,
    hasAddedProperties,
    firstName,
    lastName,
    userType,
    createdAt,
    email,
    isSubscribed,
    loading,
    isLoading,
  ]);

  return children;
}

export default FrigadeProperties;
