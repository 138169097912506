/* eslint-disable camelcase */
import { FetchResponse } from "interfaces/API";

import { fetchWrapper } from "./fetch";

export const register = async (
  register_object:
    | {
        captcha: string;
        first_name: string;
        last_name: string;
        email: string;
        password: string;
      }
    | {
        meta: string | string[];
        captcha: string;
        first_name: string;
        last_name: string;
        email: string;
        password: string;
      }
): Promise<FetchResponse> => {
  const registerResponse = await fetchWrapper({
    method: "POST",
    url: "/v2/auth/register",
    body: JSON.stringify(register_object),
    auth: false,
  });
  return registerResponse;
};

export const login = async (username: string, password: string, captcha: string): Promise<FetchResponse> => {
  const formData = new URLSearchParams();
  formData.append("username", username);
  formData.append("password", password);
  formData.append("captcha", captcha);

  const loginResponse = await fetchWrapper({
    method: "POST",
    url: "/v2/auth/login",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: formData,
    auth: false,
  });
  return loginResponse;
};

export const forgot = async (email: string, captcha: string): Promise<FetchResponse> =>
  fetchWrapper({
    method: "POST",
    url: "/v2/auth/recover",
    auth: false,
    body: JSON.stringify({
      captcha,
      email,
    }),
  });

export const logout = async (): Promise<FetchResponse> =>
  fetchWrapper({
    method: "POST",
    url: `/v2/auth/logout`,
    auth: true,
  });

export const isLoggedIn = async (): Promise<FetchResponse> =>
  fetchWrapper({
    method: "POST",
    url: `/v2/auth/validate`,
    auth: true,
  });

export const resetPassword = async (
  token: string | string[] | undefined,
  new_password: string,
  captcha: string
): Promise<FetchResponse> =>
  fetchWrapper({
    method: "POST",
    url: "/v2/auth/reset-password",
    body: JSON.stringify({
      token,
      new_password,
      captcha,
    }),
    auth: false,
  });

export const confirmEmail = async (token: string): Promise<FetchResponse> =>
  fetchWrapper({
    method: "POST",
    url: `/v2/auth/confirm?token=${encodeURIComponent(token)}`,
    auth: false,
  });

export const changeEmail = async (email: string, captcha: string): Promise<FetchResponse> =>
  fetchWrapper({
    method: "POST",
    url: "/v2/auth/change-email",
    body: JSON.stringify({ email, captcha }),
    auth: true,
  });

export const confirmChangeEmail = async (token: string): Promise<FetchResponse> =>
  fetchWrapper({
    method: "POST",
    url: `/v2/auth/confirm-change?token=${encodeURIComponent(token)}`,
    auth: false,
  });

export const resendConfirmation = async (email: string, captcha: string): Promise<FetchResponse> =>
  fetchWrapper({
    method: "POST",
    url: "/v2/auth/resend-confirmation",
    body: JSON.stringify({
      email,
      captcha,
    }),
    auth: false,
  });

export const changePassword = async (password_payload: {
  current_password: string;
  password: string;
  password_confirm: string;
}): Promise<FetchResponse> =>
  fetchWrapper({
    method: "POST",
    url: "/v2/auth/change-password",
    body: JSON.stringify(password_payload),
    auth: true,
  });

export const invalidateTokens = async (): Promise<FetchResponse> =>
  fetchWrapper({
    method: "POST",
    url: "/v2/auth/invalidate-tokens",
    auth: true,
  });
