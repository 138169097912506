import useSWR from "swr";

import { candidateAPI } from "utils/apis";
import { DEACTIVATED_USER_MESSAGE } from "utils/constants";
import useAuth from "utils/hooks/useAuth";

export function limitedCandidateDataKey(): string {
  return `/candidate/limited/data`;
}

interface UseLimitedCandidateDataReturn {
  onBoardingCompleted: boolean;
  preferencesCompleted: boolean;
  candidateId: string | null;
  error: Error | null;
  isLoading: boolean;
}

// eslint-disable-next-line import/prefer-default-export
export function useLimitedCandidateData(): UseLimitedCandidateDataReturn {
  const { isAuthenticated, logout, loading } = useAuth();

  const { data, isLoading, ...rest } = useSWR(
    isAuthenticated ? limitedCandidateDataKey() : "",
    () => {
      if (isAuthenticated) {
        return candidateAPI.getLimitedCandidateData();
      }

      return null;
    },
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      revalidateIfStale: false,
      onSuccess: (response) => {
        if (response?.error?.detail === DEACTIVATED_USER_MESSAGE) {
          logout();
        }
      },
    }
  );

  return {
    ...rest,
    isLoading: loading || isLoading,
    onBoardingCompleted: !!data?.data?.onboarding_completed,
    preferencesCompleted: !!data?.data?.preferences_completed,
    candidateId: data?.data?.id,
  };
}
