import { useContext } from "react";

import { AuthResponse } from "interfaces/API";

import AuthContext from "../contexts/AuthContext";

const useAuth = (): AuthResponse => {
  const context = useContext(AuthContext);

  return context;
};

export default useAuth;
