import useSWR from "swr";

import { candidateAPI } from "utils/apis";
import useAuth from "utils/hooks/useAuth";

export function userSubscriptionStatusKey(): string {
  return `/user/subscription/status`;
}

interface UseSubscriptionStatusReturn {
  isSubscribed: boolean;
  error: Error | null;
  isLoading: boolean;
}

// eslint-disable-next-line import/prefer-default-export
export function useSubscriptionStatus(): UseSubscriptionStatusReturn {
  const { isAuthenticated } = useAuth();

  const { data, ...rest } = useSWR(
    isAuthenticated ? userSubscriptionStatusKey() : "",
    () => {
      if (isAuthenticated) {
        return candidateAPI.getSubscriptionStatus();
      }

      return null;
    },
    {
      shouldRetryOnError: false,
      revalidateOnFocus: true,
      revalidateIfStale: false,
    }
  );

  return {
    ...rest,
    // isLoading: loading || isLoading,
    isSubscribed: data?.data === 1,
  };
}
