import { ReactElement, useCallback, useEffect, useState } from "react";

import * as Sentry from "@sentry/nextjs";
import { useRouter } from "next/router";

import { FetchResponse } from "interfaces/API";
import * as analytics from "utils/analytics";
import { authAPI, candidateAPI } from "utils/apis";
import AuthContext from "utils/contexts/AuthContext";

function AuthProvider({ children }: { children: ReactElement }): JSX.Element {
  const router = useRouter();
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [userId, setUserId] = useState("");
  const [userType, setUserType] = useState<1 | 2>(1);
  const [createdAt, setCreatedAt] = useState("");

  const logout = useCallback(async () => {
    await authAPI.logout();
    analytics.resetUser();
    Sentry.setUser(null);
    setAuthenticated(false);
    router.push("/auth/login");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateName = (name: string): void => {
    setFirstName(name);
  };

  const checkLoggedIn = useCallback(
    async (mounted: boolean) => {
      const response = await authAPI.isLoggedIn();
      if (mounted) {
        if (response == null) {
          logout();
          setLoading(false);
        } else if (response.success) {
          analytics.removeExistingIdentify(response.data.id);
          setAuthenticated(true);
          setUserId(response.data.id);
          Sentry.setUser({ id: response.data.id });
          setEmail(response.data.email);
          setUserType(response.data.user_type);
          setFirstName(response.data.first_name);
          setLastName(response.data.last_name);
          setProfileImage(candidateAPI.getProfileImage(response.data.first_name, response.data.last_name));
          setCreatedAt(response.data.created_at);
          setLoading(false);
          localStorage.removeItem("resumeScoreTrial");
          try {
            const { method } = JSON.parse(localStorage.getItem("last_used_login") || "{}");
            if (method)
              localStorage.setItem(
                "last_used_login",
                JSON.stringify({
                  method,
                  email: response.data.email,
                  firstName: response.data.first_name,
                  lastName: response.data.last_name,
                })
              );
          } catch (e) {
            // do nothing
          }
        } else {
          setAuthenticated(false);
          setLoading(false);
        }
      }
    },
    [logout]
  );

  useEffect(() => {
    let mounted = true;
    checkLoggedIn(mounted);
    return (): void => {
      mounted = false;
    };
  }, [checkLoggedIn]);

  const login = async (emailAddr: string, password: string, captcha: string): Promise<FetchResponse> => {
    const response = await authAPI.login(emailAddr, password, captcha);
    // TODO: Keep login to 1 api call
    if (response && response.success) {
      setLoading(true);
      await checkLoggedIn(true);
    }
    return response;
  };

  const refreshAuth = async (): Promise<void> => {
    setLoading(true);
    await checkLoggedIn(true);
  };

  return (
    <AuthContext.Provider
      // we want to reload the entire auth provider if any of these values change, and want to check the context on each rerender
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        isAuthenticated,
        loading,
        email,
        firstName,
        lastName,
        profileImage,
        userId,
        userType,
        createdAt,
        login,
        logout,
        updateName,
        refreshAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
