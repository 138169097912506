/* eslint-disable camelcase */
import { FetchResponse } from "interfaces/API";

import { fetchWrapper } from "./fetch";

export const getCompanies = async (
  page: number,
  size: number,
  value: string,
  workflow_completed = false
): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/company/?page=${page}&size=${size}&value=${value}&workflow_completed=${workflow_completed}`,
  });

export const getCompany = async (company_id: string | string[] | undefined): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/company/:id/${company_id}`,
  });

export const getCompanyBySlug = async (company_slug: string | string[] | undefined): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/company/:slug/${company_slug}`,
  });

export const getCompanyJobPostings = async (
  company_id: string,
  active?: boolean,
  visible?: boolean
): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/company/:id/${company_id}/job-posting/total${active === undefined ? "" : `?active=${active}`}${
      visible === undefined ? "" : `${active === undefined ? "?" : "&"}visible=${visible}`
    }`,
  });

export const createCompany = async (company: { name: string }): Promise<FetchResponse> =>
  fetchWrapper({
    method: "POST",
    url: "/v2/company/",
    body: JSON.stringify(company),
    auth: true,
  });
