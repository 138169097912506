import "styles/tailwind.scss";
import "styles/global.scss";

import { GoogleTagManager } from "@next/third-parties/google";
import { AppProps } from "next/app";
import Router from "next/router";
import Script from "next/script";
import NProgress from "nprogress";

import AuthProvider from "components/Authentication/AuthProvider";
import ErrorBoundary from "components/ErrorBoundary";
import { FeaturebaseProvider } from "components/Featurebase/Identify";
import { AnalyticsWrapper } from "modules/analytics/AnalyticsWrapper";
import FrigadeProviderWrapper from "modules/frigade/FrigadeProviderWrapper";
import * as analytics from "utils/analytics";
import "nprogress/nprogress.css";

Router.events.on("routeChangeStart", (_, { shallow }) => {
  if (shallow) return;
  NProgress.start();
});
Router.events.on("routeChangeComplete", () => {
  NProgress.done();
  analytics.pageView();
});
Router.events.on("routeChangeError", () => NProgress.done());

if (typeof window !== "undefined" && window.posthog)
  window.posthog.onFeatureFlags(() => {
    // feature flags should be available at this point
    if (window.posthog.isFeatureEnabled("enable-session-recording")) {
      window.posthog.startSessionRecording();
    }
  });

// Check if user is using adblock
fetch("/cdn-cgi/zaraz/")
  .then(() => analytics.setUserProperty({ hasAdblock: false }))
  .catch(() => analytics.setUserProperty({ hasAdblock: true }));

function SimplifyApp({ Component, pageProps }: AppProps): JSX.Element {
  return (
    <>
      <AuthProvider>
        <AnalyticsWrapper>
          <FrigadeProviderWrapper>
            <FeaturebaseProvider>
              <ErrorBoundary>
                <Component {...pageProps} />
              </ErrorBoundary>
            </FeaturebaseProvider>
          </FrigadeProviderWrapper>
        </AnalyticsWrapper>
      </AuthProvider>
      {/* Doesnt work in documentjs (next 14) */}
      <Script data-rewardful={process.env.NEXT_PUBLIC_REWARDFUL_ID} src="https://partners.simplify.jobs/rw.js" />
      {/* eslint-disable-next-line @next/next/no-before-interactive-script-outside-document */}
      <Script id="rewardful-queue" strategy="beforeInteractive">
        {`(function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');`}
      </Script>
      <GoogleTagManager gtmId={process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID || ""} />
    </>
  );
}

export default SimplifyApp;
