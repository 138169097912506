/* eslint-disable camelcase */
import { FetchResponse } from "interfaces/API";

import { fetchWrapper } from "./fetch";

export const searchJobLists = async (
  page: number,
  size: number,
  value: string,
  job_type: number
): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/job-list/?page=${page}&size=${size}&value=${value}&job_type=${job_type}`,
  });

export const getJobList = async (job_list_id: string | string[] | undefined): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/job-list/:id/${job_list_id}`,
  });

export const getJobListBySlug = async (job_list_slug: string | string[] | undefined): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/job-list/:slug/${job_list_slug}`,
  });

export const getJobsFromJobList = async (
  job_list_id: string,
  page: number,
  size: number,
  value: string
): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/job-list/:id/${job_list_id}/job?page=${page}&size=${size}&value=${value}`,
  });

export const getJobPostingsFromJobList = async (
  job_list_id: string,
  page: number,
  size: number,
  value: string
): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/job-list/:id/${job_list_id}/job-posting?page=${page}&size=${size}&value=${value}`,
  });

export const getActiveJobsWithJobPostingsFromJobList = async (
  job_list_id: string,
  page: number,
  size: number,
  value: string
): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/job-list/:id/${job_list_id}/job-with-job-posting/active?page=${page}&size=${size}&value=${value}`,
  });

export const getJobListsFromJob = async (
  job_id: string,
  page: number,
  size: number,
  value: string
): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/job-list/:job/${job_id}/job-list?page=${page}&size=${size}&value=${value}`,
  });

export const getJobListsFromUser = async (
  user_id: string,
  page: number,
  size: number,
  value: string
): Promise<FetchResponse> =>
  fetchWrapper({
    method: "GET",
    url: `/v2/job-list/:user/${user_id}/job-list?page=${page}&size=${size}&value=${value}`,
  });

export const createJobList = async (job_list: any): Promise<FetchResponse> =>
  fetchWrapper({
    method: "POST",
    url: "/v2/job-list/",
    body: JSON.stringify(job_list),
    auth: true,
  });

export const addJobToJobList = async (job_list_id: string, job_id: string): Promise<FetchResponse> =>
  fetchWrapper({
    method: "PUT",
    url: `/v2/job-list/:id/${job_list_id}/job`,
    body: JSON.stringify(job_id),
    auth: true,
  });

export const deleteJobList = async (job_list_id: string): Promise<FetchResponse> =>
  fetchWrapper({
    method: "DELETE",
    url: `/v2/job-list/:id/${job_list_id}`,
    auth: true,
  });
