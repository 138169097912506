import * as authAPI from "./auth";
import * as candidateAPI from "./candidate";
import * as companyAPI from "./company";
import * as dataOptionsAPI from "./dataOptions";
import { fetchWrapper } from "./fetch";
import * as jobAPI from "./job";
import * as jobListAPI from "./jobList";
import * as jobPostingAPI from "./jobPosting";
import * as userAPI from "./user";

export { authAPI, userAPI, candidateAPI, dataOptionsAPI, companyAPI, jobPostingAPI, jobAPI, jobListAPI, fetchWrapper };
